import Slide1 from '../assets/img/carousel/slide1.jpg';
import Slide4 from '../assets/img/carousel/slide4.jpg';

import AboutMeImg from '../assets/img/me/mephoto.jpg'

/** Main Carousel Data */
export const mainCarouselData = [
  {
    id: 1,
    title: 'Asesoria A & M.',
    imgMe: AboutMeImg,
    img: 'https://res.cloudinary.com/dvdb33uyj/image/upload/v1689975271/Projects/unne/img/hero/financiamiento2.jpg',
  },
  {
    id: 2,
    title: 'Asesoria A & M.',
    imgMe: AboutMeImg,
    img: 'https://res.cloudinary.com/dvdb33uyj/image/upload/v1689974575/Projects/unne/img/hero/remate3.jpg',
  },
  {
    id: 3,
    title: 'Asesoria A & M.',
    imgMe: AboutMeImg,
    img: 'https://res.cloudinary.com/dvdb33uyj/image/upload/v1689975597/Projects/unne/img/hero/tramite2.jpg',
  },
  {
    id: 4,
    title: 'Asesoria A & M.',
    imgMe: AboutMeImg,
    img: 'https://res.cloudinary.com/dvdb33uyj/image/upload/v1689974937/Projects/unne/img/hero/venta.jpg',
  },
];

/** Tabs search by Property Data */
export const webServicesTabs = ['venta', 'arriendo ', 'arriendo_temporal'];




